import create from "zustand";

const initialState = Object.freeze({
	projectsPromise: {
		isLoading: false,
		isError: false,
		error: undefined,
		data: undefined
	},
	productsPromise: {
		isLoading: false,
		isError: false,
		error: undefined,
		data: undefined
	},
	usersPromise: {
		isLoading: false,
		isError: false,
		error: undefined,
		data: undefined
	},
	inviteesPromise: {
		isLoading: false,
		isError: false,
		error: undefined,
		data: undefined
	},
	tenantsPromise: {
		isLoading: false,
		isError: false,
		error: undefined,
		data: undefined
	},
	accountPromise: {
		isLoading: false,
		isError: false,
		error: undefined,
		data: undefined
	},

});

const useStore = create((set) => ({
	...initialState,

	setProjectsPromise: (val) => {
		set(() => ({
			projectsPromise: val
		}));
	},
	setProductsPromise: (val) => {
		set(() => ({
			productsPromise: val
		}));
	},
	setUsersPromise: (val) => {
		set(() => ({
			usersPromise: val
		}));
	},
	setInviteesPromise: (val) => {
		set(() => ({
			inviteesPromise: val
		}));
	},
	setTenantsPromise: (val) => {
		set(() => ({
			tenantsPromise: val
		}));
	},
	setAccountPromise: (val) => {
		set(() => ({
			accountPromise: val
		}));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useStore;
