import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import AppButton from "../../components/AppButton";
import SynamediaAuthContainer from "../../components/SynamediaAuthContainer";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import cookiesNames from "../../config/cookies";
import { Dropdown } from '@ip-synamedia/common-ui-library';
import { getDomain } from "../../config";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',  // Add this to center items horizontally
		alignSelf: "center",
		width: 372,
		//height: 400,
		margin: "0 auto 0 auto"
	},
	formContainer: {
		width: 372,
		display: "flex",
		flexDirection: "column",
		alignSelf: "center"

	},
	titleContainer: {
		width: 346,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: "center",
		marginBottom: '16px'
	},
	userInputContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	selectAccountButton: {
		width: '100%',
		height: 40,
		marginTop: 35,
		marginBottom: 40,
		fontWeight: 400,
		fontFamily: 'Source Sans Pro'
	},
	labelWelcome: {
		height: 84,
		width: '80%',
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 28,
		textAlign: 'center',
		color: '#11161D',
	},
	label: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		textAlign: 'center',
		color: '#293447',
	},
	userLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		textAlign: 'center',
		color: '#293447',
		marginTop: '32px'
	},
	selectAccountLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		textAlign: 'center',
		color: '#293447',
		marginTop: '32px',
		marginBottom: '16px'
	},
	selectUserLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		textAlign: 'center',
		color: '#293447',
		marginBottom: '16px',
		marginTop: '32px'
	},
	userBtn:{
		color: '#2966CC',
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		fontWeight: 400,
		alignSelf: 'center',
		marginBottom: '8px'
	},
	historyBtn:{
		color: '#2966CC',
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		fontWeight: 400,
		alignSelf: 'center',
		marginTop: '8px'
	},
}));

const MultiAccountLoginHome = () => {
	const styles = useStyles();
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies([cookiesNames.accountAlias, cookiesNames.accounts, cookiesNames.userId]);
	let accountsCookie = cookies?.[cookiesNames.accounts] ?? [];

	// if the last used user id is not defined e.g. user has removed it, then we'll default to the first user in the remaining list
	const lastUsedUserId = cookies?.[cookiesNames.userId] ?? accountsCookie?.[0]?.userId;

	// If we still don't have a user then go to loginHome, where the account name entry screen will be seen.
	// This scenario can occur if the user deletes all users and is returned to the account name entry screen and then
	// presses back or directly uses the multi account URL when there is no user defined.
	useEffect(() => {
		if (!lastUsedUserId) navigate(`/loginHome`);
	}, [lastUsedUserId, navigate]);

	const [selectedUser, setSelectedUser] = useState(lastUsedUserId);
	const userDropdownOptions = accountsCookie.map(entry => ({ id: entry.userId, label: entry.userId }));
	const accountsForSelectedUser = accountsCookie.find(entry => entry.userId === selectedUser)?.accounts ?? [];

	accountsForSelectedUser.sort((a, b) => a.alias.localeCompare(b.alias));
	const accountDropdownOptions = accountsForSelectedUser.map(entry => ({ id : entry.alias, label: entry.alias }));

	// For the account selection dropdown box we default to showing the last logged into account.
	// If there isn't one then default to the first account in the list.
	let lastLoggedIntoAccount = cookies?.[cookiesNames.accountAlias];

	if (!lastLoggedIntoAccount && accountsForSelectedUser?.length > 0) {
		lastLoggedIntoAccount = accountsForSelectedUser[0].alias;
	}

	const [selectedAccount, setSelectedAccount] = useState(lastLoggedIntoAccount);
	const handleAccountSelectionChange = (value) => setSelectedAccount(value);
	const handleUseDifferentUserClick = () => navigate(`/loginHome?enterAccountName=true`);
	const handleLoginClick = () => navigate(`/${selectedAccount}/home?email=${selectedUser}`);

	const handleUserChanged = (value) => {
		setSelectedUser(value);

		// If the selected user is changed then we need to update the default selected account.
		// If the last logged into account does not exist for the newly selected user then we default to the first
		// account in the list.
		const accountsForNewUser = accountsCookie.find(entry => entry.userId === value)?.accounts ?? [];

		setSelectedAccount(accountsForNewUser.find(entry => entry.alias === lastLoggedIntoAccount) ?
			lastLoggedIntoAccount : accountsForNewUser[0].alias);
	};

	const handleRemoveUserClick = () => {
		const userIndex = accountsCookie.findIndex(entry => entry.userId === selectedUser);

		if (userIndex !== -1) {
			// remove this user and all of their accounts from the cookie
			accountsCookie.splice(userIndex, 1);
			setCookie(cookiesNames.accounts, accountsCookie, {
				path: '/',
				maxAge: 86400 * 365 * 10,
				...(!window.location.hostname.includes('localhost') && { domain: `.${getDomain()}` })
			});

			// If the last used user was removed then remove the cookie.
			if (lastUsedUserId === selectedUser) removeCookie(cookiesNames.userId);

			// If there are still users left, default the selected user to the first one in the list.
			if (accountsCookie.length > 0) {
				setSelectedUser(accountsCookie[0].userId);
			} else {
				// User has removed all their email accounts from their list so remove all history and go to login
				// page where they will see the 'enter account name' screen.
				removeCookie(cookiesNames.accountAlias);
				removeCookie(cookiesNames.accountOrgId);
				removeCookie(cookiesNames.accounts);
				navigate(`/loginHome`);
			}
		}
	};

	const content = (
		<div className={styles.rootContainer}>
			<div className={styles.labelWelcome} id={'LoginHome_labelWelcome'}>Welcome to the account-portal</div>
			{accountsCookie.length > 1 ?
				<>
					<div className={styles.selectUserLabel} id={'LoginHome_label'}>We have identified that the following user is a member of multiple accounts</div>
					<div className={styles.userInputContainer}>
						<Dropdown options={userDropdownOptions} value={selectedUser} onValueChange={handleUserChanged} width="100%"/>
					</div>
				</> :
				<div className={styles.selectUserLabel} id={'LoginHome_label'}>We have identified that the user {selectedUser} is a member of multiple accounts</div>
			}
			<AppButton
				variant={'text'}
				overrideClasses={{ button: styles.historyBtn }}
				id={'btn_user'}
				onClick={handleRemoveUserClick}
			>
				Remove this user from your history
			</AppButton>
			<AppButton
				variant={'text'}
				overrideClasses={{ button: styles.userBtn }}
				id={'btn_user'}
				onClick={handleUseDifferentUserClick}
			>
				Sign in using a different user
			</AppButton>
			<div className={styles.selectAccountLabel} id={'LoginHome_label'}>Please select an account to continue</div>
			<div className={styles.formContainer}>
				<div className={styles.userInputContainer}>
					<Dropdown options={accountDropdownOptions} value={selectedAccount} onValueChange={handleAccountSelectionChange} width="100%"/>
				</div>
				<AppButton
					variant={'contained'}
					type={'submit'}
					overrideClasses={{ button: styles.selectAccountButton }}
					id={'btn_login'}
					onClick={handleLoginClick}
				>
					Sign In
				</AppButton>
			</div>
		</div>
	);

	document.title = 'Synamedia Cloud Portal | Login';

	return (
		<SynamediaAuthContainer
			content={content}
		/>
	);
};

export default MultiAccountLoginHome;
